// src/contexts/ChatContext.js
import React, { createContext, useState, useContext } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatState, setChatState] = useState({
    pendingRequests: [],
    responses: [],
    draftPost: null,
    isMinimized: false,
  });

  const value = {
    chatState,
    setChatState,
    minimizeChat: () => setChatState(prev => ({ ...prev, isMinimized: true })),
    maximizeChat: () => setChatState(prev => ({ ...prev, isMinimized: false })),
  };

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);