import { db, auth } from '../firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDoc, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  arrayUnion, 
  arrayRemove, 
  serverTimestamp,
  runTransaction
} from 'firebase/firestore';
import Post from '../models/Post';
import { createNotification } from './notificationService';

const canInteractWithPost = async (postId, userId) => {
  const postRef = doc(db, 'posts', postId);
  const postSnap = await getDoc(postRef);
  
  if (!postSnap.exists()) throw new Error('Post not found');
  
  const postData = postSnap.data();
  
  if (postData.privacy === 'public') return true;
  if (postData.privacy === 'friends') {
    return await checkFriendship(postData.userId, userId);
  }
  return postData.userId === userId;
};

export const createPost = async (name, description, link, privacy, searchMetadata) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to create a post');

  if (!name || !description) {
    throw new Error('Name and description are required for creating a post.');
  }

  const newPost = new Post(
    null,
    user.uid,
    name,
    description,
    link || '',
    privacy || 'public',
    serverTimestamp(),
    serverTimestamp(),
    [],
    [],
    0,
    searchMetadata || []
  );

  const docRef = await addDoc(collection(db, 'posts'), newPost.toFirestore());
  return { ...newPost, id: docRef.id };
};

export const updatePost = async (postId, updates) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to update a post');

  const postRef = doc(db, 'posts', postId);
  const postSnap = await getDoc(postRef);

  if (!postSnap.exists()) throw new Error('Post not found');
  if (postSnap.data().userId !== user.uid) throw new Error('User does not have permission to update this post');

  const updatedPost = {
    ...postSnap.data(),
    ...updates,
    updatedAt: serverTimestamp()
  };

  await updateDoc(postRef, updatedPost);
  
  // Fetch the updated document to ensure we have the server timestamp
  const updatedPostSnap = await getDoc(postRef);
  return Post.fromFirestore(updatedPostSnap);
};

export const deletePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to delete a post');

  const postRef = doc(db, 'posts', postId);
  const postSnap = await getDoc(postRef);

  if (!postSnap.exists()) throw new Error('Post not found');
  if (postSnap.data().userId !== user.uid) throw new Error('User does not have permission to delete this post');

  await deleteDoc(postRef);
};

export const getPost = async (postId) => {
  const postRef = doc(db, 'posts', postId);
  const postSnap = await getDoc(postRef);

  if (!postSnap.exists()) throw new Error('Post not found');
  return { objectID: postSnap.id, ...postSnap.data() };
};

export const getUserPosts = async (userId, currentUserId) => {
  const user = auth.currentUser;
  if (!user) throw new Error("User must be logged in to fetch posts");

  try {
    let q;
    if (userId === currentUserId) {
      // User viewing their own posts, show all
      q = query(
        collection(db, "posts"),
        where("userId", "==", userId),
        orderBy("updatedAt", "desc")
      );
    } else {
      // Check if the current user is friends with the profile owner
      const areFriends = await checkFriendship(userId, currentUserId);

      if (areFriends) {
        // Friends can see public and friends-only posts
        q = query(
          collection(db, "posts"),
          where("userId", "==", userId),
          where("privacy", "in", ["public", "friends"]),
          orderBy("updatedAt", "desc")
        );
      } else {
        // Non-friends can only see public posts
        q = query(
          collection(db, "posts"),
          where("userId", "==", userId),
          where("privacy", "==", "public"),
          orderBy("updatedAt", "desc")
        );
      }
    }

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => Post.fromFirestore(doc));
  } catch (error) {
    console.error("Error fetching posts:", error);
    return []; // Return an empty array if there's an error
  }
};

export const likePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to like a post');

  const postRef = doc(db, 'posts', postId);

  try {
    let updatedPost;
    await runTransaction(db, async (transaction) => {
      const postDoc = await transaction.get(postRef);
      if (!postDoc.exists()) {
        throw new Error('Post does not exist!');
      }

      const postData = postDoc.data();
      const newLikes = postData.likes || [];

      if (!newLikes.includes(user.uid)) {
        newLikes.push(user.uid);
        transaction.update(postRef, { likes: newLikes });

        // Create notification
        if (postData.userId !== user.uid) {
          try {
            await createNotification(postData.userId, 'like', postId);
          } catch (notificationError) {
            console.error('Error creating notification:', notificationError);
            // Continue with the like operation even if notification fails
          }
        }
      }
      updatedPost = { objectID: postId, ...postData, likes: newLikes };
    });

    return updatedPost;
  } catch (error) {
    console.error('Error liking post:', error);
    throw error;
  }
};

export const unlikePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to unlike a post');

  const postRef = doc(db, 'posts', postId);
  
  try {
    const postDoc = await getDoc(postRef);
    if (!postDoc.exists()) {
      throw new Error('Post does not exist!');
    }

    const postData = postDoc.data();
    const newLikes = postData.likes.filter(id => id !== user.uid);

    await updateDoc(postRef, {
      likes: newLikes
    });

    return { objectID: postId, ...postData, likes: newLikes };
  } catch (error) {
    console.error('Error unliking post:', error);
    throw error;
  }
};

export const commentOnPost = async (postId, comment) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to comment on a post');

  const postRef = doc(db, 'posts', postId);
  const postSnap = await getDoc(postRef);
  
  if (!postSnap.exists()) throw new Error('Post not found');
  
  const postData = postSnap.data();

  // Allow post owner to comment regardless of other permissions
  if (postData.userId !== user.uid && !(await canInteractWithPost(postId, user.uid))) {
    throw new Error('You do not have permission to comment on this post');
  }

  const newComment = {
    userId: user.uid,
    content: comment,
    createdAt: new Date().toISOString()
  };

  const updatedComments = [...(postData.comments || []), newComment];

  await updateDoc(postRef, {
    comments: updatedComments
  });

  // Create notification only if the commenter is not the post owner
  if (postData.userId !== user.uid) {
    await createNotification(postData.userId, 'comment', postId);
  }

  return { objectID: postId, ...postData, comments: updatedComments };
};

export const sharePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to share a post');

  const postRef = doc(db, 'posts', postId);

  try {
    const postDoc = await getDoc(postRef);
    if (!postDoc.exists()) {
      throw new Error('Post does not exist!');
    }

    const postData = postDoc.data();
    const newShares = (postData.shares || 0) + 1;

    await updateDoc(postRef, { shares: newShares });

    // Create notification
    if (postData.userId !== user.uid) {
      await createNotification(postData.userId, 'share', postId);
    }

    return { objectID: postId, ...postData, shares: newShares };
  } catch (error) {
    console.error('Detailed error in sharePost:', error);
    if (error.code === 'permission-denied') {
      console.error('Permission denied. Current security rules may be preventing this operation.');
    }
    throw error;
  }
};

export const savePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to save a post');

  if (!(await canInteractWithPost(postId, user.uid))) {
    throw new Error('You do not have permission to save this post');
  }

  const userRef = doc(db, 'users', user.uid);
  await updateDoc(userRef, {
    savedPosts: arrayUnion(postId)
  });

  // Fetch and return the updated user data
  const updatedUserDoc = await getDoc(userRef);
  return updatedUserDoc.data();
};

export const unsavePost = async (postId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to unsave a post');

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  const savedPosts = userDoc.data().savedPosts || [];

  if (savedPosts.includes(postId)) {
    await updateDoc(userRef, {
      savedPosts: arrayRemove(postId)
    });
  }

  // Fetch and return the updated user data
  const updatedUserDoc = await getDoc(userRef);
  return updatedUserDoc.data();
};

const checkFriendship = async (userId1, userId2) => {
  const user1Doc = await getDoc(doc(db, 'users', userId1));
  const user2Doc = await getDoc(doc(db, 'users', userId2));

  if (!user1Doc.exists() || !user2Doc.exists()) {
    return false;
  }

  const user1Friends = user1Doc.data().friends || [];
  const user2Friends = user2Doc.data().friends || [];

  return user1Friends.includes(userId2) && user2Friends.includes(userId1);
};

export const testPostUpdate = async (postId) => {
  const postRef = doc(db, 'posts', postId);
  try {
    await updateDoc(postRef, { testField: 'test' });
    console.log('Test update successful');
    return true;
  } catch (error) {
    console.error('Test update failed:', error);
    return false;
  }
};

export const getUserSavedPosts = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be logged in to fetch saved posts');

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    throw new Error('User document not found');
  }

  const savedPostIds = userDoc.data().savedPosts || [];

  // Function to fetch a batch of posts
  const fetchPostBatch = async (ids) => {
    const q = query(
      collection(db, 'posts'),
      where("__name__", "in", ids),
      orderBy("updatedAt", "desc")
    );
    
    try {
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error(`Error fetching batch of posts:`, error);
      return [];
    }
  };

  // Split savedPostIds into batches of 10
  const batches = [];
  for (let i = 0; i < savedPostIds.length; i += 10) {
    batches.push(savedPostIds.slice(i, i + 10));
  }

  // Fetch all batches
  const batchResults = await Promise.all(batches.map(fetchPostBatch));

  // Merge results
  const allPosts = batchResults.flat();

  // Handle missing posts and permission issues
  const validatedPosts = await Promise.all(
    allPosts.map(async (post) => {
      if (!post) {
        return null;
      }
      try {
        const postDoc = await getDoc(doc(db, 'posts', post.id));
        if (!postDoc.exists()) {
          console.log(`Saved post ${post.id} no longer exists. Removing from saved posts.`);
          await unsavePost(post.id);
          return null;
        }
        return post;
      } catch (error) {
        console.error(`Error fetching saved post ${post.id}:`, error);
        if (error.code === 'permission-denied') {
          console.log(`User no longer has permission to access post ${post.id}. Removing from saved posts.`);
          await unsavePost(post.id);
        }
        return null;
      }
    })
  );

  // Filter out any null values and sort by updatedAt
  const finalPosts = validatedPosts.filter(post => post !== null);
  finalPosts.sort((a, b) => b.updatedAt.toMillis() - a.updatedAt.toMillis());

  return finalPosts;
};