// src/utils/featureDetection.js
import { isSupported } from "firebase/messaging";

export const isNotificationSupported = () => {
  return 'Notification' in window;
};

export const isServiceWorkerSupported = () => {
  return 'serviceWorker' in navigator;
};

export const isFirebaseMessagingSupported = async () => {
  if (!isServiceWorkerSupported()) {
    return false;
  }
  try {
    return await isSupported();
  } catch (error) {
    console.error("Error checking Firebase Messaging support:", error);
    return false;
  }
};