// src/hooks/usePost.js
import { usePostContext } from '../contexts/PostContext';
import {
  updatePost as updatePostService,
  likePost as likePostService,
  unlikePost as unlikePostService,
  commentOnPost as commentOnPostService,
  deletePost as deletePostService,
  sharePost as sharePostService,
  savePost as savePostService,
  unsavePost as unsavePostService,
  getUserPosts as getUserPostsService,
  getUserSavedPosts as getUserSavedPostsService
} from '../services/postService';

export function usePost() {
  const { posts, setPosts } = usePostContext();

  const updatePostInState = (updatedPost) => {
    setPosts(currentPosts =>
      currentPosts.map(post =>
        post.id === updatedPost.id ? { ...post, ...updatedPost } : post
      )
    );
    return updatedPost;
  };

  const updatePost = async (postId, updateData) => {
    // Optimistic update
    setPosts(currentPosts => 
      currentPosts.map(post => 
        post.id === postId ? { ...post, ...updateData } : post
      )
    );

    try {
      const updatedPost = await updatePostService(postId, updateData);
      return updatePostInState(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPosts(currentPosts => 
        currentPosts.map(post => 
          post.id === postId ? post : post
        )
      );
      throw error;
    }
  };

  const likePost = async (postId) => {
    try {
      const updatedPost = await likePostService(postId);
      return updatePostInState(updatedPost);
    } catch (error) {
      console.error('Error liking post:', error);
      throw error;
    }
  };

  const unlikePost = async (postId) => {
    try {
      const updatedPost = await unlikePostService(postId);
      return updatePostInState(updatedPost);
    } catch (error) {
      console.error('Error unliking post:', error);
      throw error;
    }
  };

  const commentOnPost = async (postId, comment) => {
    try {
      const updatedPost = await commentOnPostService(postId, comment);
      return updatePostInState(updatedPost);
    } catch (error) {
      console.error('Error commenting on post:', error);
      throw error;
    }
  };

  const deletePost = async (postId) => {
    try {
      await deletePostService(postId);
      setPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
      throw error;
    }
  };

  const sharePost = async (postId) => {
    try {
      const updatedPost = await sharePostService(postId);
      return updatePostInState(updatedPost);
    } catch (error) {
      console.error('Error sharing post:', error);
      throw error;
    }
  };

  const savePost = async (postId) => {
    try {
      const updatedUserData = await savePostService(postId);
      return updatedUserData;
    } catch (error) {
      console.error('Error saving post:', error);
      throw error;
    }
  };
  
  const unsavePost = async (postId) => {
    try {
      const updatedUserData = await unsavePostService(postId);
      return updatedUserData;
    } catch (error) {
      console.error('Error unsaving post:', error);
      throw error;
    }
  };

  return {
    posts,
    setPosts,
    updatePost,
    likePost,
    unlikePost,
    commentOnPost,
    deletePost,
    sharePost,
    savePost,
    unsavePost,
    getUserPosts: getUserPostsService,
    getUserSavedPosts: getUserSavedPostsService
  };
}