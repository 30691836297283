import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { InstantSearch, Configure } from 'react-instantsearch';
import searchClient, { ALGOLIA_INDEX_NAME } from '../config/algoliaConfig';
import ProfileForm from '../components/ProfileForm';
import PostList from '../components/PostList';
import SearchBar from '../components/SearchBar';
import Button from '../components/Button';
import { PostProvider } from '../contexts/PostContext';
import { usePost } from '../hooks/usePost';
import '../styles/Profile.css';

const Profile = ({ initialTab = 'posts' }) => {
  const { userId: paramUserId } = useParams();
  const [profile, setProfile] = useState(null);
  const [savedPosts, setSavedPosts] = useState([]);
  const [savedPostIds, setSavedPostIds] = useState([]);
  const [editing, setEditing] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [searchState, setSearchState] = useState({});
  const [profileUserId, setProfileUserId] = useState(null);
  const [userPosts, setUserPosts] = useState([]);

  const { setPosts, getUserPosts, getUserSavedPosts } = usePost();

  const fetchProfileAndPosts = useCallback(async () => {
    setLoading(true);
    setError(null);
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('No user logged in');
      setLoading(false);
      return;
    }

    const profileId = paramUserId || currentUser.uid;
    setIsCurrentUser(profileId === currentUser.uid);
    setProfileUserId(profileId);

    try {
      // Fetch profile
      const docRef = doc(db, 'users', profileId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const profileData = docSnap.data();
        setProfile(profileData);
        setSavedPostIds(profileData.savedPosts || []);
      } else {
        setError('Profile not found');
      }

      // Fetch posts
      const fetchedPosts = await getUserPosts(profileId, currentUser.uid);
      setUserPosts(fetchedPosts); 
      setPosts(fetchedPosts);

      // Fetch saved posts if it's the current user
      if (profileId === currentUser.uid) {
        const fetchedSavedPosts = await getUserSavedPosts();
        setSavedPosts(fetchedSavedPosts);
      }
    } catch (error) {
      console.error('Error fetching profile or posts:', error);
      setError('Error fetching profile or posts');
    }

    setLoading(false);
  }, [paramUserId, setPosts, getUserPosts, getUserSavedPosts]);

  useEffect(() => {
    fetchProfileAndPosts();
  }, [fetchProfileAndPosts]);

  const handleSearchStateChange = (nextSearchState) => {
    setSearchState(nextSearchState);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(updatedProfile);
    setEditing(false);
    fetchProfileAndPosts(); // Refresh posts after profile update
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error-message">Error: {error}</div>;
  if (!profile) return <div className="error-message">Profile not found</div>;

  const filterString = activeTab === 'saved' 
    ? `objectID:${savedPostIds.join(' OR objectID:')}`
    : `userId:${profileUserId}`;

  return (
    <PostProvider>
      <div className="profile-container">
        <div className="profile-header">
          {editing ? (
            <ProfileForm
              existingProfile={profile}
              onProfileUpdate={handleProfileUpdate}
              onCancel={handleCancelEdit}
            />
          ) : (
            <>
              <h2 className="profile-name">{profile.displayName}</h2>
              <p className="profile-username">@{profile.username}</p>
              <p className="profile-bio">{profile.bio}</p>
              {isCurrentUser && (
                <Button 
                  onClick={handleEditProfile}
                  variant="primary"
                  size="medium"
                  className="profile-edit-button"
                >
                  Edit Profile
                </Button>
              )}
            </>
          )}
        </div>
        <div className="profile-tabs">
          <button
            className={`profile-tab ${activeTab === 'posts' ? 'active' : ''}`}
            onClick={() => handleTabChange('posts')}
          >
            Posts
          </button>
          {isCurrentUser && (
            <button
              className={`profile-tab ${activeTab === 'saved' ? 'active' : ''}`}
              onClick={() => handleTabChange('saved')}
            >
              Saved Posts
            </button>
          )}
        </div>
        <InstantSearch 
          searchClient={searchClient} 
          indexName={ALGOLIA_INDEX_NAME}
          searchState={searchState}
          onSearchStateChange={handleSearchStateChange}
        >
          <Configure filters={filterString} />
          <div className="search-container">
            <SearchBar />
          </div>
          <div className="profile-content">
            <PostList 
              defaultPosts={activeTab === 'posts' ? userPosts : savedPosts}
              isCurrentUser={isCurrentUser} 
              activeTab={activeTab}
            />
          </div>
        </InstantSearch>
      </div>
    </PostProvider>
  );
};

export default Profile;