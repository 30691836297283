import React from 'react';
import ChatInterface from '../components/ChatInterface';

const Chatbot = () => {
  return (
    <div className="chatbot-page">
      {/* <h2>Affin</h2> */}
      <ChatInterface />
    </div>
  );
};

export default Chatbot;