import React, { useState } from 'react';
import { auth } from '../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Button from './Button';
import './Auth.css';

const Auth = ({ onAuthSuccess }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const setupRecaptcha = () => {
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (!recaptchaContainer) {
      console.error('recaptcha-container not found');
      return;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }
  };

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError('');
    if (!phoneNumber) {
      setError('Please enter a valid phone number.');
      return;
    }
    setIsLoading(true);
    setupRecaptcha();
    try {
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
      setConfirmationResult(confirmation);
    } catch (error) {
      console.error("Error sending verification code:", error);
      setError('Failed to send verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError('');
    if (!confirmationResult) {
      setError("No confirmation result available");
      return;
    }
    setIsLoading(true);
    try {
      const userCredential = await confirmationResult.confirm(verificationCode);
      console.log("User signed in successfully");
      onAuthSuccess(userCredential.user);
    } catch (error) {
      console.error("Error verifying code:", error);
      setError('Invalid verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-title">Welcome to Affin</h2>
      {!confirmationResult ? (
        <form onSubmit={handleSendCode} className="auth-form">
          <div className="input-group">
            <label htmlFor="phone-input" className="input-label">Phone Number</label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="phone-input"
            />
          </div>
          <Button 
            type="submit" 
            variant="primary" 
            size="large" 
            disabled={isLoading || !phoneNumber}
          >
            {isLoading ? 'Sending...' : 'Send Verification Code'}
          </Button>
        </form>
      ) : (
        <form onSubmit={handleVerifyCode} className="auth-form">
          <div className="input-group">
            <label htmlFor="verification-code" className="input-label">Verification Code</label>
            <input
              id="verification-code"
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter 6-digit code"
              className="auth-input"
            />
          </div>
          <Button type="submit" variant="primary" size="large" disabled={isLoading}>
            {isLoading ? 'Verifying...' : 'Verify Code'}
          </Button>
        </form>
      )}
      {error && <p className="auth-error" role="alert">{error}</p>}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default Auth;