import React, { useState, useEffect, useRef, useCallback } from 'react';
import { auth, db } from '../firebase';
import { 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  limit, 
  onSnapshot,
  where,
  getDocs
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { RefreshCw, Send, Loader } from 'react-feather';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Button from './Button';
import { getSessionInfo, manualRefresh, setConversationId, refreshToken } from '../authManager';
import ChatPostList from './ChatPostList';
import PostPreview from './PostPreview';
import { getUserSavedPosts, createPost } from '../services/postService';
import './ChatInterface.css';
import icon from '../assets/icon.png';
import { useChat } from '../contexts/ChatContext';

const ChatInterface = () => {
  const { chatState, setChatState } = useChat();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [savedPosts, setSavedPosts] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const functions = getFunctions();
  const difyChat = httpsCallable(functions, 'difyChat');

  useEffect(() => {
    const fetchSessionInfo = async () => {
      const info = await getSessionInfo();
      setSessionInfo(info);
    };

    fetchSessionInfo();
    const sessionCheckInterval = setInterval(fetchSessionInfo, 60000);

    return () => clearInterval(sessionCheckInterval);
  }, []);

  useEffect(() => {
    const getLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            console.error("Error obtaining location:", error);
            getFallbackLocation();
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        getFallbackLocation();
      }
    };
  
    getLocation();
  }, []);

  const getFallbackLocation = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.latitude && data.longitude) {
        setUserLocation({
          latitude: data.latitude,
          longitude: data.longitude
        });
      }
    } catch (error) {
      console.error("Error getting fallback location:", error);
      setUserLocation(null);
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const q = query(
        collection(db, `users/${user.uid}/messages`),
        orderBy('timestamp', 'desc'),
        limit(50)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedMessages = [];
        querySnapshot.forEach((doc) => {
          fetchedMessages.push({ id: doc.id, ...doc.data() });
        });
        setMessages(fetchedMessages.reverse());
      });

      // Fetch saved posts
      fetchSavedPosts();

      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages.length]);

  useEffect(() => {
    if (chatState.draftPost) {
      setMessages(prevMessages => {
        const existingPreviewIndex = prevMessages.findIndex(
          msg => msg.type === 'post_preview' && msg.post.id === chatState.draftPost.id
        );
        
        if (existingPreviewIndex === -1) {
          return [...prevMessages, {
            id: Date.now(),
            type: 'post_preview',
            post: chatState.draftPost,
            sender: 'bot',
            timestamp: new Date()
          }];
        }
        
        return prevMessages;
      });
    }
  }, [chatState.draftPost]);

  // New effect for auto-expanding textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const fetchSavedPosts = async () => {
    try {
      const savedPostsData = await getUserSavedPosts();
      setSavedPosts(savedPostsData.map(post => post.id));
    } catch (error) {
      console.error('Error fetching saved posts:', error);
    }
  };

  const userInfoCache = new Map();

  const fetchPostDetails = async (postIds) => {
    // Guard clause to handle empty postIds array
    if (postIds.length === 0) return [];

    try {
      const postsRef = collection(db, 'posts');
      const q = query(postsRef, where('__name__', 'in', postIds));
      const querySnapshot = await getDocs(q);
      
      const posts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      const userIds = [...new Set(posts.map(post => post.userId))];
      const uncachedUserIds = userIds.filter(id => !userInfoCache.has(id));
  
      if (uncachedUserIds.length > 0) {
        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('__name__', 'in', uncachedUserIds));
        const userSnapshot = await getDocs(userQuery);
  
        userSnapshot.forEach(doc => {
          const userData = doc.data();
          userInfoCache.set(doc.id, {
            displayName: userData.displayName || 'Anonymous',
            username: userData.username || ''
          });
        });
      }
  
      const postsWithAuthors = posts.map(post => {
        const userInfo = userInfoCache.get(post.userId) || { displayName: 'Anonymous', username: '' };
        const authorDisplay = userInfo.username 
          ? `${userInfo.displayName} (@${userInfo.username})`
          : userInfo.displayName;
        return {
          ...post,
          authorDisplay
        };
      });
  
      return postsWithAuthors;
    } catch (error) {
      console.error('Error fetching post details:', error);
      return [];
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '' || !sessionInfo) return;
  
    const user = auth.currentUser;
    if (!user) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      // Add user message to messages
      const userMessage = {
        id: Date.now(),
        text: input,
        sender: 'user',
        timestamp: new Date(),
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
  
      await addDoc(collection(db, `users/${user.uid}/messages`), userMessage);
  
      let latestSessionInfo = await getSessionInfo();
      
      if (!latestSessionInfo.conversationId) {
        await refreshToken();
        latestSessionInfo = await getSessionInfo(true);
      }
  
      setChatState(prevState => ({
        ...prevState,
        pendingRequests: [...prevState.pendingRequests, input],
      }));

      const result = await difyChat({
        query: input,
        conversationId: latestSessionInfo.conversationId,
        user_firebase_token: latestSessionInfo.token,
        coordinates: userLocation
      });
      
      console.log("Dify Chat Result:", result.data);
  
      const { botResponse, conversationId: newConversationId } = result.data;
  
      if (botResponse && botResponse.trim() !== "") {
        let parsedResponse;
        try {
          parsedResponse = JSON.parse(botResponse);
        } catch (error) {
          console.log("Bot response is not JSON, treating as plain text");
          parsedResponse = { message: botResponse };
        }
  
        // Always add the bot's message to the chat
        const botMessage = {
          id: Date.now(),
          text: parsedResponse.message || parsedResponse.result?.message || "I've received your message.",
          sender: 'bot',
          timestamp: new Date(),
        };
        setMessages(prevMessages => [...prevMessages, botMessage]);
  
        // Save the bot's message to Firebase
        await addDoc(collection(db, `users/${user.uid}/messages`), botMessage);
  
        // If there's a post, add it as a separate preview message
        if (parsedResponse.post) {
          setChatState(prevState => ({
            ...prevState,
            draftPost: parsedResponse.post,
            pendingRequests: prevState.pendingRequests.filter(req => req !== input),
            responses: [...prevState.responses, parsedResponse],
          }));
          
          const postPreviewMessage = {
            id: Date.now() + 1,
            sender: 'bot',
            timestamp: new Date(),
            type: 'post_preview',
            post: parsedResponse.post,
          };
          setMessages(prevMessages => [...prevMessages, postPreviewMessage]);
        } else if (parsedResponse.result && parsedResponse.result.posts) {
          const postIds = parsedResponse.result.posts.map(post => post.id);
          
          const searchResultMessage = {
            id: Date.now() + 2,
            sender: 'bot',
            timestamp: new Date(),
            type: 'search_results',
            postIds: postIds,
          };
          setMessages(prevMessages => [...prevMessages, searchResultMessage]);

          // Save search results to Firebase
          await addDoc(collection(db, `users/${user.uid}/messages`), {
            text: postIds.length > 0 ? "Here are the search results:" : "No posts found.",
            sender: 'bot',
            timestamp: new Date(),
            type: 'search_results',
            postIds: postIds,
          });

          setChatState(prevState => ({
            ...prevState,
            pendingRequests: prevState.pendingRequests.filter(req => req !== input),
            responses: [...prevState.responses, parsedResponse],
          }));
        }
  
        if (newConversationId && newConversationId !== latestSessionInfo.conversationId) {
          await setConversationId(newConversationId);
          setSessionInfo({ ...latestSessionInfo, conversationId: newConversationId });
        }
      } else {
        console.error('No bot response received or response is empty');
        setError('No response from the bot. Please try again.');
      }
      setInput('');
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
      // Attempt to refresh the session on error
      await handleManualRefresh();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePost = async (post) => {
    setIsLoading(true);
    setError(null);

    try {
      if (!post.name || !post.description) {
        throw new Error('Name and description are required for creating a post.');
      }
      
      const result = await createPost(
        post.name,
        post.description,
        post.link || '',
        post.privacy || 'public',
        post.searchMetadata || []
      );
      
      setMessages(prevMessages => [...prevMessages, 
        { id: Date.now(), sender: 'bot', text: `Post "${result.name}" has been created successfully!` }
      ]);
      setChatState(prevState => ({
        ...prevState,
        draftPost: null,
      }));
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualRefresh = async () => {
    try {
      await manualRefresh();
      const info = await getSessionInfo(true);  // Force a refresh
      setSessionInfo(info);
      console.log('Session manually refreshed');
      setMessages([]); // Clear the chat history
      setError(null);
      setChatState(prevState => ({
        ...prevState,
        draftPost: null,
        pendingRequests: [],
        responses: [],
      }));
    } catch (error) {
      console.error('Error during manual refresh:', error);
      setError('Failed to refresh the session. Please try again later.');
    }
  };

  const startNewConversation = async () => {
    if (window.confirm('Are you sure you want to start a new conversation? This will clear your chat history and any draft posts.')) {
      await handleManualRefresh();
    }
  };

  const handlePostUpdate = useCallback((updatedPost) => {
    setMessages(prevMessages => prevMessages.map(message => {
      if (message.type === 'search_results') {
        return {
          ...message,
          postIds: message.postIds.map(id => 
            id === updatedPost.id ? updatedPost.id : id
          )
        };
      }
      return message;
    }));
  }, []);

  const handleSavedPostsUpdate = (updatedSavedPosts) => {
    setSavedPosts(updatedSavedPosts);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const MarkdownLink = ({ href, children }) => {
    const isExternal = href.startsWith('http');
    return isExternal ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ) : (
      <Link to={href}>{children}</Link>
    );
  };

  return (
    <div className="chat-interface">
      <div className="chat-header">
        <div className="left-section">
          <img src={icon} alt="Affin Icon" className="affin-icon" />
          <h2>Affin</h2>
        </div>
        <button onClick={startNewConversation} className="refresh-button" aria-label="Start new conversation">
          <RefreshCw size={20} />
          <span>New Chat</span>
        </button>
      </div>
      {chatState.draftPost && (
        <div className="draft-post-indicator">
          You have a draft post. Would you like to continue editing?
          <Button onClick={() => {
            const existingPreview = messages.find(
              msg => msg.type === 'post_preview' && msg.post.id === chatState.draftPost.id
            );
            
            if (!existingPreview) {
              setMessages(prevMessages => [...prevMessages, { 
                id: Date.now(),
                type: 'post_preview',
                post: chatState.draftPost,
                sender: 'bot',
                timestamp: new Date()
              }]);
            }
          }}>
            Continue Editing
          </Button>
        </div>
      )}
      <div className="message-list">
        {messages.map((message) => (
          <div key={message.id} className={`message-container ${message.sender}`}>
            {message.type === 'search_results' ? (
              <div className="search-results">
                <ChatPostList 
                  postIds={message.postIds}
                  getPostDetails={fetchPostDetails}
                  onPostUpdated={handlePostUpdate}
                  savedPosts={savedPosts}
                  onSavedPostsUpdated={handleSavedPostsUpdate}
                />
              </div>
            ) : message.type === 'post_preview' ? (
              <div className="post-preview-container">
                <PostPreview 
                  post={message.post} 
                  onPostCreated={handleCreatePost}
                  onClose={() => {
                    setMessages(prevMessages => 
                      prevMessages.filter(msg => msg.id !== message.id)
                    );
                    setChatState(prevState => ({
                      ...prevState,
                      draftPost: null,
                    }));
                  }}
                />
              </div>
            ) : (
              <div className={`message ${message.sender}`}>
                <ReactMarkdown
                  components={{
                    a: MarkdownLink
                  }}
                >
                  {message.text}
                </ReactMarkdown>
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={sendMessage} className="message-input">
        <textarea
          ref={textareaRef}
          value={input}
          onChange={handleInputChange}
          placeholder="Type a message..."
          disabled={isLoading}
          rows={1}
          style={{
            resize: 'none',
            overflow: 'hidden',
            maxHeight: '200px', // Adjust as needed
          }}
        />
        <button
          type="submit"
          disabled={isLoading || input.trim().length === 0}
          className={`send-button ${isLoading ? 'loading' : ''}`}
          aria-label={isLoading ? "Processing message" : "Send message"}
        >
          {isLoading ? <Loader size={20} className="loader" /> : <Send size={20} />}
        </button>
      </form>
    </div>
  );
};

export default ChatInterface;