import React from 'react';
import './Button.css';

const Button = ({ children, variant = 'primary', size = 'medium', onClick, disabled, type = 'button', fullWidth = false }) => {
  const classNames = `affin-button ${variant} ${size} ${fullWidth ? 'full-width' : ''}`;
  
  return (
    <button 
      className={classNames} 
      onClick={onClick} 
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;