import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, setDoc, doc, arrayUnion } from 'firebase/firestore';
import Button from './Button';

const AddFriend = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddFriend = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    if (!phoneNumber.trim()) {
      setMessage('Please enter a valid phone number');
      setIsLoading(false);
      return;
    }

    const currentUser = auth.currentUser;

    try {
      // Normalize the phone number by removing spaces and ensuring it starts with '+'
      const normalizedPhoneNumber = '+' + phoneNumber.replace(/\s+/g, '').replace(/^\+/, '');

      // Query for the user with the given phone number
      const q = query(collection(db, "users"), where("phoneNumber", "==", normalizedPhoneNumber));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage('No user found with this phone number');
        setIsLoading(false);
        return;
      }

      const friendDoc = querySnapshot.docs[0];
      const friendId = friendDoc.id;

      if (friendId === currentUser.uid) {
        setMessage('You cannot add yourself as a friend');
        setIsLoading(false);
        return;
      }

      // Send friend request
      await setDoc(doc(db, 'users', friendId), {
        friendRequests: arrayUnion(currentUser.uid)
      }, { merge: true });

      setMessage('Friend request sent successfully');
      setPhoneNumber('');
    } catch (error) {
      console.error("Error sending friend request: ", error);
      if (error.code === 'permission-denied') {
        setMessage('Permission denied. Please try again later.');
      } else {
        setMessage('An error occurred while sending friend request');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleAddFriend} className="add-friend-form">
      <input
        type="tel"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter friend's phone number (e.g., +10000000000)"
        className="add-friend-input"
        disabled={isLoading}
      />
      <Button 
        type="submit" 
        variant="primary" 
        size="medium"
        disabled={isLoading}
        className="add-friend-button"
      >
        {isLoading ? 'Sending...' : 'Send Friend Request'}
      </Button>
      {message && <p className="add-friend-message">{message}</p>}
    </form>
  );
};

export default AddFriend;