import React from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import BottomNavigation from './BottomNavigation';
import Header from './Header';
import './Layout.css';

const Layout = ({ children }) => {
  const handleLogout = () => {
    signOut(auth).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  return (
    <div className="layout">
      <Header onLogout={handleLogout} />
      <main className="main-content">{children}</main>
      <BottomNavigation />
    </div>
  );
};

export default Layout;