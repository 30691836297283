export default class Post {
  constructor(id, userId, name, description, link, privacy, createdAt, updatedAt, likes = [], comments = [], shares = 0, searchMetadata = []) {
    this.id = id;
    this.userId = userId;
    this.name = name;
    this.description = description;
    this.link = link;
    this.privacy = privacy;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.likes = likes;
    this.comments = comments;
    this.shares = shares;
    this.searchMetadata = searchMetadata;
  }

  static fromFirestore(doc) {
    const data = doc.data();
    return new Post(
      doc.id,
      data.userId,
      data.name,
      data.description,
      data.link,
      data.privacy,
      data.createdAt instanceof Object && 'toDate' in data.createdAt ? data.createdAt.toDate() : data.createdAt,
      data.updatedAt instanceof Object && 'toDate' in data.updatedAt ? data.updatedAt.toDate() : data.updatedAt,
      data.likes || [],
      data.comments || [],
      data.shares || 0,
      data.searchMetadata || []
    );
  }

  toFirestore() {
    return {
      userId: this.userId,
      name: this.name,
      description: this.description,
      link: this.link || '',
      privacy: this.privacy || 'public',
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      likes: this.likes,
      comments: this.comments,
      shares: this.shares,
      searchMetadata: this.searchMetadata || []
    };
  }
}