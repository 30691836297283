// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getMessaging } from "firebase/messaging";
import { isFirebaseMessagingSupported } from './utils/featureDetection';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export let messaging = null;

(async () => {
  try {
    const messagingSupported = await isFirebaseMessagingSupported();
    if (messagingSupported) {
      messaging = getMessaging(app);
      console.log("Firebase Messaging initialized successfully.");
    } else {
      console.log("Firebase Messaging is not supported on this browser.");
    }
  } catch (error) {
    console.error("Error initializing Firebase Messaging:", error);
  }
})();

export { onAuthStateChanged };
export { app };