import React, { useState, useEffect } from 'react';
import { isToday, isYesterday, isThisWeek } from 'date-fns';
import NotificationItem from './NotificationItem';
import { getNotifications } from '../services/notificationService';
import './NotificationsDropdown.css';

const groupNotifications = (notifications) => {
  const groups = {
    Today: [],
    Yesterday: [],
    ThisWeek: [],
    Earlier: []
  };

  notifications.forEach(notification => {
    const date = notification.createdAt && notification.createdAt.toDate ?
      notification.createdAt.toDate() :
      new Date();

    if (isToday(date)) {
      groups.Today.push(notification);
    } else if (isYesterday(date)) {
      groups.Yesterday.push(notification);
    } else if (isThisWeek(date)) {
      groups.ThisWeek.push(notification);
    } else {
      groups.Earlier.push(notification);
    }
  });

  return groups;
};

const NotificationsDropdown = ({ isOpen, onClose }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const fetchedNotifications = await getNotifications();
      setNotifications(fetchedNotifications);
    };

    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);

  const groupedNotifications = groupNotifications(notifications);

  if (!isOpen) return null;

  return (
    <div className="notifications-dropdown">
      <h2 className="notifications-header">Notifications</h2>
      {Object.entries(groupedNotifications).map(([group, groupNotifications]) => (
        groupNotifications.length > 0 && (
          <div key={group} className="notification-group">
            <h3 className="group-header">{group}</h3>
            {groupNotifications.map(notification => (
              <NotificationItem 
                key={notification.id} 
                notification={notification} 
                onClose={onClose}
              />
            ))}
          </div>
        )
      ))}
      {notifications.length === 0 && (
        <div className="empty-state">
          <p>You're all caught up!</p>
          <p>Check back later for new notifications.</p>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;