// src/services/fcmTokenService.js
import { messaging } from '../firebase';
import { getToken, deleteToken } from "firebase/messaging";
import { auth, db } from '../firebase';
import { doc, setDoc, deleteField, serverTimestamp, getDoc } from 'firebase/firestore';
import { isFirebaseMessagingSupported, isNotificationSupported } from '../utils/featureDetection';

const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const logTokenOperation = (operation, details) => {
  console.log(`FCM Token Operation: ${operation}`, details);
};

const isTokenValid = async (token) => {
  try {
    // Attempt to get a new token. If successful, the current token is still valid.
    await getToken(messaging, { vapidKey: VAPID_KEY, serviceWorkerRegistration: await navigator.serviceWorker.getRegistration() });
    return true;
  } catch (error) {
    console.error('Token validation failed:', error);
    return false;
  }
};

export const initializeFCM = async () => {
  const isSupported = await isFirebaseMessagingSupported();
  if (!isSupported) {
    console.log('Firebase Messaging is not supported on this browser');
    return null;
  }

  logTokenOperation('Initializing FCM', {});
  try {
    const token = await getAndSaveFCMToken();
    if (token) {
      logTokenOperation('FCM initialized successfully', {});
      return token;
    } else {
      logTokenOperation('FCM initialization deferred - no permission or token', {});
      return null;
    }
  } catch (error) {
    logTokenOperation('Error initializing FCM', { error: error.message });
    return null;
  }
};

export const getAndSaveFCMToken = async () => {
  try {
    logTokenOperation('Getting token', {});
    const currentToken = await getToken(messaging, { 
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration: await navigator.serviceWorker.getRegistration()
    });
    if (currentToken) {
      logTokenOperation('Token received', { tokenLength: currentToken.length });
      await saveFCMToken(currentToken);
      return currentToken;
    } else {
      logTokenOperation('No token available', {});
      console.log('No registration token available. Request permission to generate one.');
      return null;
    }
  } catch (error) {
    logTokenOperation('Error getting token', { error: error.message });
    console.error('An error occurred while retrieving token. ', error);
    return null;
  }
};

export const saveFCMToken = async (token) => {
  const user = auth.currentUser;
  if (user) {
    try {
      logTokenOperation('Saving token', { userId: user.uid });
      const userRef = doc(db, 'users', user.uid);
      
      // Attempt to get the user document
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        // User document exists, update it
        const userData = userDoc.data();
        if (!userData.fcmToken || userData.fcmToken !== token) {
          await setDoc(userRef, { 
            fcmToken: token,
            fcmTokenUpdatedAt: serverTimestamp()
          }, { merge: true });
          logTokenOperation('Token saved (existing user)', { userId: user.uid });
        } else {
          logTokenOperation('Token unchanged', { userId: user.uid });
        }
      } else {
        // User document doesn't exist, create it
        await setDoc(userRef, {
          fcmToken: token,
          fcmTokenUpdatedAt: serverTimestamp(),
          createdAt: serverTimestamp()
        });
        logTokenOperation('Token saved (new user)', { userId: user.uid });
      }
      
      console.log('FCM Token saved successfully');
      return true;
    } catch (error) {
      logTokenOperation('Error saving token', { error: error.message });
      console.error('Error saving FCM token: ', error);
      return false;
    }
  }
  return false;
};

export const removeFCMToken = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      logTokenOperation('Removing token', { userId: user.uid });
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const storedToken = userDoc.data()?.fcmToken;

      if (storedToken) {
        const isValid = await isTokenValid(storedToken);
        if (isValid) {
          await deleteToken(messaging);
        }
      }

      // Always remove the token from Firestore, regardless of whether deleteToken succeeds
      await setDoc(doc(db, 'users', user.uid), { 
        fcmToken: deleteField(),
        fcmTokenUpdatedAt: deleteField()
      }, { merge: true });

      logTokenOperation('Token removed', { userId: user.uid });
      console.log('FCM Token removed successfully');
    } catch (error) {
      logTokenOperation('Error removing token', { error: error.message });
      console.error('Error removing FCM token: ', error);
      // Even if there's an error, we should still try to remove the token from Firestore
      try {
        await setDoc(doc(db, 'users', user.uid), { 
          fcmToken: deleteField(),
          fcmTokenUpdatedAt: deleteField()
        }, { merge: true });
        logTokenOperation('Token removed from Firestore after error', { userId: user.uid });
      } catch (firestoreError) {
        logTokenOperation('Error removing token from Firestore', { error: firestoreError.message });
      }
    }
  }
};

export const refreshFCMToken = async () => {
  logTokenOperation('Refreshing token', {});
  await removeFCMToken();
  const newToken = await getAndSaveFCMToken();
  if (newToken) {
    logTokenOperation('Token refreshed successfully', {});
    return newToken;
  } else {
    logTokenOperation('Failed to refresh token', {});
    return null;
  }
};

export const setupTokenRefresh = () => {
  logTokenOperation('Setting up token refresh', {});
  // Refresh token every month
  setInterval(async () => {
    try {
      await verifyAndUpdateFCMToken();
      console.log('FCM token verification completed');
    } catch (error) {
      console.error('Error during FCM token verification:', error);
    }
  }, 30 * 24 * 60 * 60 * 1000); // 30 days
};

export const verifyAndUpdateFCMToken = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      logTokenOperation('Verifying token', { userId: user.uid });
      const currentToken = await getToken(messaging, { vapidKey: VAPID_KEY });
      
      if (currentToken) {
        await saveFCMToken(currentToken);
        logTokenOperation('Token verified and saved', { userId: user.uid });
      } else {
        logTokenOperation('No token available, requesting permission', { userId: user.uid });
        await requestNotificationPermission();
      }
    } catch (error) {
      logTokenOperation('Error verifying token', { error: error.message });
      console.error('Error verifying and updating FCM token:', error);
    }
  }
};

export const requestNotificationPermission = async () => {
  if (!isNotificationSupported()) {
    console.log('Notifications are not supported on this browser');
    return { permission: 'denied', token: null };
  }

  try {
    logTokenOperation('Requesting notification permission', {});
    const permission = await Notification.requestPermission();
    logTokenOperation('Notification permission result', { permission });
    if (permission === 'granted') {
      const isSupported = await isFirebaseMessagingSupported();
      if (isSupported) {
        const token = await getAndSaveFCMToken();
        return { permission, token };
      }
    }
    return { permission, token: null };
  } catch (error) {
    logTokenOperation('Error requesting permission', { error: error.message });
    console.error('An error occurred while requesting permission ', error);
    return { permission: 'denied', token: null };
  }
};

export const refreshTokenOnInit = async () => {
  logTokenOperation('Refreshing token on init', {});

  const maxRetries = 3;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      // Check current permission without prompting
      const permission = Notification.permission;
      logTokenOperation('Current notification permission', { permission });

      // Try to get the current token regardless of permission
      const registration = await navigator.serviceWorker.ready;
      logTokenOperation('Service Worker is ready', {});

      let currentToken = await getToken(messaging, { 
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration: registration
      });
      
      // If no current token, force a refresh
      if (!currentToken) {
        logTokenOperation('No current token, attempting to force refresh', {});
        try {
          await deleteToken(messaging);
          currentToken = await getToken(messaging, { 
            vapidKey: VAPID_KEY,
            serviceWorkerRegistration: registration
          });
          if (currentToken) {
            logTokenOperation('Successfully obtained new token after forced refresh', {});
          } else {
            logTokenOperation('Failed to obtain new token after forced refresh', {});
          }
        } catch (error) {
          logTokenOperation('Error during forced token refresh', { error: error.message });
          throw error; // Re-throw to be caught by the outer try-catch
        }
      }

      if (currentToken) {
        const savedSuccessfully = await saveFCMToken(currentToken);
        if (savedSuccessfully) {
          logTokenOperation('FCM token refreshed and saved', {});
          return currentToken;
        } else {
          throw new Error('Failed to save FCM token');
        }
      } else {
        logTokenOperation('No FCM token available', {});
        if (permission !== 'granted') {
          logTokenOperation('Notification permission not granted', {});
        }
        return null;
      }
    } catch (error) {
      logTokenOperation('Error refreshing FCM token', { error: error.message, retry: retries + 1 });
      console.error(`Error refreshing FCM token (attempt ${retries + 1}):`, error);
      retries++;
      
      if (retries >= maxRetries) {
        console.error('Max retries reached. Unable to refresh FCM token.');
        return null;
      }
      
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
  }

  return null;
};

export const ensureValidFCMToken = async () => {
  const user = auth.currentUser;
  if (!user) return null;

  const isSupported = await isFirebaseMessagingSupported();
  if (!isSupported) {
    console.log('Firebase Messaging is not supported on this browser');
    return null;
  }

  try {
    logTokenOperation('Ensuring valid FCM token', { userId: user.uid });
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const storedToken = userDoc.data()?.fcmToken;

    if (storedToken && await isTokenValid(storedToken)) {
      logTokenOperation('Stored token is valid', { userId: user.uid });
      return storedToken;
    } else {
      logTokenOperation('Stored token is invalid or missing, getting new token', { userId: user.uid });
      return await refreshFCMToken();
    }
  } catch (error) {
    logTokenOperation('Error ensuring valid FCM token', { userId: user.uid, error: error.message });
    console.error('Error ensuring valid FCM token:', error);
    return null;
  }
};