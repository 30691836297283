import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Bell, LogOut } from 'react-feather';
import NotificationsDropdown from './NotificationsDropdown';
import logo from '../assets/logo.png';
import { getUnreadNotificationsCount, markAllNotificationsAsRead } from '../services/notificationService';
import './Header.css';

const Header = ({ onLogout }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchUnreadCount = useCallback(async () => {
    const count = await getUnreadNotificationsCount();
    setUnreadCount(count);
  }, []);

  useEffect(() => {
    fetchUnreadCount();
    const timer = setInterval(fetchUnreadCount, 60000); // Check every minute
    return () => clearInterval(timer);
  }, [fetchUnreadCount]);

  const handleNotificationClick = async () => {
    if (!isNotificationsOpen) {
      await markAllNotificationsAsRead();
      setUnreadCount(0);
    }
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <header className="app-header">
      <Link to="/" className="logo-link">
        <img src={logo} alt="Affin Logo" className="logo" />
      </Link>
      <div className="header-actions">
        <button 
          className="notifications-button"
          onClick={handleNotificationClick}
          aria-label="Notifications"
        >
          <Bell size={24} />
          {unreadCount > 0 && (
            <span className="notification-badge">{unreadCount > 99 ? '99+' : unreadCount}</span>
          )}
        </button>
        <NotificationsDropdown 
          isOpen={isNotificationsOpen} 
          onClose={() => setIsNotificationsOpen(false)}
        />
        <button 
          className="logout-button"
          onClick={onLogout}
          aria-label="Logout"
        >
          <LogOut size={24} />
        </button>
      </div>
    </header>
  );
};

export default Header;