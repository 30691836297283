import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Button from './Button';
import { validateUsername } from '../utils/validation';
import './ProfileForm.css';

const ProfileForm = ({ existingProfile, onProfileUpdate, onCancel }) => {
  const [displayName, setDisplayName] = useState(existingProfile?.displayName || '');
  const [username, setUsername] = useState(existingProfile?.username || '');
  const [originalUsername, setOriginalUsername] = useState(existingProfile?.username || '');
  const [bio, setBio] = useState(existingProfile?.bio || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState({});

  useEffect(() => {
    if (existingProfile) {
      setDisplayName(existingProfile.displayName || '');
      setUsername(existingProfile.username || '');
      setOriginalUsername(existingProfile.username || '');
      setBio(existingProfile.bio || '');
    }
  }, [existingProfile]);

  const handleUsernameChange = (e) => {
    const lowercaseInput = e.target.value.toLowerCase();
    setUsername(lowercaseInput);
    setTouched({ ...touched, username: true });
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  const validateForm = () => {
    const errors = {};
    if (!displayName.trim()) errors.displayName = "Display name is required";
    const usernameError = validateUsername(username);
    if (usernameError) errors.username = usernameError;
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const user = auth.currentUser;
    if (!user) {
      setError({ general: "No authenticated user found" });
      setIsSubmitting(false);
      return;
    }

    const normalizedPhoneNumber = '+' + user.phoneNumber.replace(/\s+/g, '').replace(/^\+/, '');

    try {
      let updatedUsername = originalUsername;

      if (username !== originalUsername) {
        const functions = getFunctions();
        const setUsernameFunction = httpsCallable(functions, 'setUsername');
        const result = await setUsernameFunction({ username });

        if (!result.data.success) {
          throw new Error('Failed to set username');
        }
        updatedUsername = username;
      }

      const profileData = {
        displayName,
        username: updatedUsername,
        bio,
        phoneNumber: normalizedPhoneNumber,
        updatedAt: new Date(),
      };

      await setDoc(doc(db, 'users', user.uid), profileData, { merge: true });
      setOriginalUsername(updatedUsername);
      onProfileUpdate(profileData);
    } catch (error) {
      console.error("Error updating profile: ", error);
      setError({ general: error.message || "Failed to update profile. Please try again." });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="profile-form">
      <div className="form-group">
        <label htmlFor="displayName">Display Name</label>
        <input
          id="displayName"
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          onBlur={() => handleBlur('displayName')}
          placeholder="Enter your display name"
          required
          aria-required="true"
          aria-invalid={touched.displayName && !displayName.trim()}
        />
        {touched.displayName && !displayName.trim() && (
          <p className="error-message">Display name is required</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={handleUsernameChange}
          onBlur={() => handleBlur('username')}
          placeholder="Enter your username (lowercase letters, numbers, underscores)"
          required
          aria-required="true"
          aria-invalid={touched.username && !!validateUsername(username)}
        />
        {touched.username && validateUsername(username) && (
          <p className="error-message">{validateUsername(username)}</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="bio">Bio</label>
        <textarea
          id="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          placeholder="Tell us about yourself"
          aria-label="Your bio"
        />
      </div>
      {error && typeof error === 'object' && 'general' in error && (
        <p className="error-message" role="alert">{error.general}</p>
      )}
      <div className="profile-form-actions">
        <Button 
          type="button" 
          variant="secondary" 
          size="medium" 
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          type="submit" 
          variant="primary" 
          size="medium" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save Profile'}
        </Button>
      </div>
    </form>
  );
};

export default ProfileForm;