// src/pages/Friends.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import AddFriend from '../components/AddFriend';
import AddFriendByUsername from '../components/AddFriendByUsername';
import FriendRequests from '../components/FriendRequests';
import '../styles/Friends.css';

const Friends = () => {
  const [friends, setFriends] = useState([]);
  const [addMethod, setAddMethod] = useState('phone'); // 'phone' or 'username'

  useEffect(() => {
    const fetchFriends = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const friendIds = userDoc.data().friends || [];
        
        const friendsData = await Promise.all(
          friendIds.map(async (friendId) => {
            const friendDoc = await getDoc(doc(db, 'users', friendId));
            return { id: friendId, ...friendDoc.data() };
          })
        );

        setFriends(friendsData);
      }
    };

    fetchFriends();
  }, []);

  const getDisplayName = (friend) => {
    if (friend.displayName) return friend.displayName;
    if (friend.username) return friend.username;
    return "Anonymous Friend";
  };

  const getAvatarLetter = (friend) => {
    const displayName = getDisplayName(friend);
    return displayName.charAt(0).toUpperCase();
  };

  return (
    <div className="friends-page">
      <div className="friends-header">
        <h2>Friends</h2>
      </div>
      <div className="add-friend-section">
        <div className="add-friend-tabs">
          <button 
            className={`add-friend-tab ${addMethod === 'phone' ? 'active' : ''}`}
            onClick={() => setAddMethod('phone')}
          >
            Add by Phone
          </button>
          <button 
            className={`add-friend-tab ${addMethod === 'username' ? 'active' : ''}`}
            onClick={() => setAddMethod('username')}
          >
            Add by Username
          </button>
        </div>
        <div className="add-friend-content">
          {addMethod === 'phone' ? <AddFriend /> : <AddFriendByUsername />}
        </div>
      </div>
      <FriendRequests />
      <div className="friends-list">
        <h3>My Friends</h3>
        <ul>
          {friends.map((friend) => (
            <li key={friend.id} className="friend-item">
              <div className="friend-avatar">
                {getAvatarLetter(friend)}
              </div>
              <div className="friend-info">
                <Link to={`/profile/${friend.id}`}>
                  {getDisplayName(friend)}
                </Link>
                {friend.username && (
                  <span className="friend-username">@{friend.username}</span>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Friends;