// src/authManager.js
import { auth, db } from './firebase';
import { doc, setDoc, getDoc, deleteField } from 'firebase/firestore';

let currentUser = null;
let sessionRefreshTimeout = null;

export const initAuthListener = () => {
  auth.onAuthStateChanged((user) => {
    currentUser = user;
    if (user) {
      scheduleSessionRefresh().catch(error => {
        console.error('Error scheduling session refresh:', error);
      });
    } else {
      clearTimeout(sessionRefreshTimeout);
    }
  }, (error) => {
    console.error('Auth state change error:', error);
  });
};

const scheduleSessionRefresh = async () => {
  if (!currentUser) return;

  const tokenResult = await currentUser.getIdTokenResult();
  const expirationTime = new Date(tokenResult.expirationTime).getTime();
  const timeToRefresh = expirationTime - Date.now() - (5 * 60 * 1000); // Refresh 5 minutes before expiration

  clearTimeout(sessionRefreshTimeout);
  sessionRefreshTimeout = setTimeout(() => refreshSession(), timeToRefresh);
};

const refreshSession = async () => {
  if (!currentUser) return;

  try {
    await currentUser.getIdToken(true);
    
    // Delete the conversation ID and its expiration time from Firestore
    await setDoc(doc(db, 'users', currentUser.uid), {
      conversationId: deleteField(),
      conversationIdExpiresAt: deleteField(),
      lastRefresh: new Date().toISOString()
    }, { merge: true });

    console.log('Session refreshed, conversation ID deleted');
    scheduleSessionRefresh();
  } catch (error) {
    console.error('Error refreshing session:', error);
  }
};

export const refreshToken = async () => {
  if (!currentUser) throw new Error('No user logged in');
  await currentUser.getIdToken(true);
  console.log('Auth token refreshed');
};

export const setConversationId = async (conversationId) => {
  if (!currentUser) return;

  const conversationIdExpiresAt = new Date(Date.now() + 60 * 60 * 1000); // 1 hour from now
  
  await setDoc(doc(db, 'users', currentUser.uid), {
    conversationId: conversationId,
    conversationIdExpiresAt: conversationIdExpiresAt
  }, { merge: true });
};

export const getSessionInfo = async (forceRefresh = false) => {
  if (!currentUser) throw new Error('No user logged in');

  try {
    if (forceRefresh) {
      await refreshToken();
    }

    const token = await currentUser.getIdToken();
    const userDocRef = doc(db, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    
    let conversationId = null;
    if (userData && userData.conversationIdExpiresAt) {
      const now = new Date();
      if (userData.conversationIdExpiresAt.toDate() > now) {
        conversationId = userData.conversationId;
      } else {
        // If expired, remove the conversationId and expiration time
        await setDoc(userDocRef, {
          conversationId: deleteField(),
          conversationIdExpiresAt: deleteField()
        }, { merge: true });
      }
    }

    return { token, conversationId };
  } catch (error) {
    console.error('Error getting session info:', error);
    throw error;
  }
};

// Expose refreshSession for manual refresh
export const manualRefresh = refreshSession;