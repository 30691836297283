import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Heart, MessageSquare, Share2, Bookmark, Edit2, Trash2, ChevronDown } from 'react-feather';
import Button from './Button';
import ConfirmationModal from './ConfirmationModal';
import styles from './PostItem.module.css';
import { usePost } from '../hooks/usePost';

const PostItem = ({ post: initialPost, isDetailView = false }) => {
  const [post, setPost] = useState(initialPost);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [canInteract, setCanInteract] = useState(false);
  const [isPostExpanded, setIsPostExpanded] = useState(isDetailView);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(post.name);
  const [description, setDescription] = useState(post.description);
  const [link, setLink] = useState(post.link);
  const [privacy, setPrivacy] = useState(post.privacy);
  const [comment, setComment] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [shareMessage, setShareMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [commenters, setCommenters] = useState({});
  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(false);
  const commentInputRef = useRef(null);

  const navigate = useNavigate();
  const { updatePost, deletePost, likePost, unlikePost, commentOnPost, sharePost, savePost, unsavePost } = usePost();

  const postId = post.objectID || post.id;

  const checkUserPermissions = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setIsCurrentUser(currentUser.uid === post.userId);
      if (post.privacy === 'public') {
        setCanInteract(true);
      } else if (post.privacy === 'friends') {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        const friends = userDoc.data().friends || [];
        setCanInteract(friends.includes(post.userId) || currentUser.uid === post.userId);
      } else {
        setCanInteract(currentUser.uid === post.userId);
      }

      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const savedPosts = userDoc.data().savedPosts || [];
      setIsSaved(savedPosts.includes(postId));
    }
  }, [post.userId, post.privacy, postId]);

  const fetchCommentersDisplayNames = useCallback(async () => {
    const commenterIds = [...new Set(post.comments.map(comment => comment.userId))];
    const fetchedCommenters = {};

    for (const id of commenterIds) {
      const userDoc = await getDoc(doc(db, 'users', id));
      if (userDoc.exists()) {
        fetchedCommenters[id] = userDoc.data().displayName || 'Anonymous';
      } else {
        fetchedCommenters[id] = 'Anonymous';
      }
    }

    setCommenters(fetchedCommenters);
  }, [post.comments]);

  useEffect(() => {
    checkUserPermissions();
    fetchCommentersDisplayNames();
  }, [checkUserPermissions, fetchCommentersDisplayNames, post]);

  useEffect(() => {
    if (isCommentSectionVisible && commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, [isCommentSectionVisible]);

  const handleUpdate = async () => {
    try {
      const updatedPost = await updatePost(postId, { name, description, link, privacy });
      setPost(updatedPost);
      setEditing(false);
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePost(postId);
      setIsDeleteModalOpen(false);
      if (isDetailView) {
        navigate('/');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleLike = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    // Optimistic update
    const newLikes = post.likes.includes(currentUser.uid)
      ? post.likes.filter(id => id !== currentUser.uid)
      : [...post.likes, currentUser.uid];
    
    setPost(prevPost => ({ ...prevPost, likes: newLikes }));
  
    try {
      const updatedPost = await (post.likes.includes(currentUser.uid) ? unlikePost(postId) : likePost(postId));
      setPost(updatedPost);
    } catch (error) {
      // Revert optimistic update on error
      setPost(prevPost => ({ ...prevPost, likes: post.likes }));
      console.error("Error toggling like:", error);
    }
  };

  const handleComment = useCallback(async (e) => {
    e.preventDefault();
    try {
      const updatedPost = await commentOnPost(postId, comment);
      setPost(updatedPost);
      setComment('');
      setCommenters(prevCommenters => ({
        ...prevCommenters,
        [auth.currentUser.uid]: auth.currentUser.displayName || 'Anonymous'
      }));
    } catch (error) {
      console.error('Error commenting on post:', error);
    }
  }, [comment, commentOnPost, postId]);

  const handleShare = async () => {
    try {
      const updatedPost = await sharePost(postId);
      setPost(updatedPost);
      const postUrl = `${window.location.origin}/post/${postId}`;
      
      if (navigator.share) {
        await navigator.share({
          title: post.name,
          text: 'Check out this post!',
          url: postUrl
        });
        setShareMessage('Post shared successfully!');
      } else if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(postUrl);
        setShareMessage('Post URL copied to clipboard!');
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = postUrl;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setShareMessage('Post URL copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing post:', error);
      setShareMessage('Failed to share. Please try again or share manually.');
    } finally {
      setTimeout(() => setShareMessage(''), 3000);
    }
  };

  const handleSave = async () => {
    // Optimistic update
    setIsSaved(prevState => !prevState);
  
    try {
      if (isSaved) {
        await unsavePost(postId);
      } else {
        await savePost(postId);
      }
      // The actual state will be updated in the next render due to the API call
    } catch (error) {
      // Revert optimistic update on error
      setIsSaved(prevState => !prevState);
      console.error("Error toggling save:", error);
    }
  };

  const togglePostExpansion = useCallback(() => {
    setIsPostExpanded(prev => {
      if (!prev) {
        return true; // If we're expanding the post, just return true
      } else {
        // If we're collapsing the post, also collapse the comment section
        setIsCommentSectionVisible(false);
        return false;
      }
    });
  }, []);

  const toggleCommentSection = useCallback(() => {
    setIsCommentSectionVisible(prev => !prev);
    setIsPostExpanded(true); // Always expand the post when toggling comments
  }, []);

  const renderEditForm = () => (
    <div className={styles.postEditForm}>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Post Title"
        aria-label="Post Title"
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Post Description"
        aria-label="Post Description"
      />
      <input
        value={link}
        onChange={(e) => setLink(e.target.value)}
        placeholder="Link"
        aria-label="Post Link"
      />
      <select
        value={privacy}
        onChange={(e) => setPrivacy(e.target.value)}
        aria-label="Post Privacy Setting"
      >
        <option value="public">Public</option>
        <option value="friends">Friends</option>
        <option value="private">Private</option>
      </select>
      <div className={styles.postEditActions}>
        <Button onClick={handleUpdate} variant="primary" size="small">Save</Button>
        <Button onClick={() => setEditing(false)} variant="secondary" size="small">Cancel</Button>
      </div>
    </div>
  );

  const renderPostContent = () => (
    <div className={`${styles.postContent} ${isPostExpanded || isDetailView ? styles.expanded : ''}`}>
      <div className={styles.postTitleWrapper}>
        <h3 className={styles.postTitle}>
          {isDetailView ? post.name : <Link to={`/post/${postId}`}>{post.name}</Link>}
        </h3>
        {!isDetailView && (
          <ChevronDown
            className={`${styles.expandIcon} ${isPostExpanded ? styles.expanded : ''}`}
            size={20}
            aria-hidden="true"
          />
        )}
      </div>
      {(isPostExpanded || isDetailView) && (
        <>
          <div className={styles.postDescription}>
            <ReactMarkdown>{post.description}</ReactMarkdown>
          </div>
          {post.link && (
            <a href={post.link} target="_blank" rel="noopener noreferrer" className={styles.postLink}>
              View Link
            </a>
          )}
          <p className={styles.postPrivacy}>Privacy: {post.privacy}</p>
        </>
      )}
    </div>
  );

  const renderPostActions = () => (
    <div className={styles.postActions}>
      <Button
        onClick={handleLike}
        variant="tertiary"
        size="small"
        aria-label={post.likes.includes(auth.currentUser.uid) ? 'Unlike post' : 'Like post'}
      >
        <Heart 
          fill={post.likes.includes(auth.currentUser.uid) ? 'var(--color-primary)' : 'none'}
          stroke={post.likes.includes(auth.currentUser.uid) ? 'var(--color-primary)' : 'currentColor'}
        />
        <span>{post.likes.length}</span>
      </Button>
      <Button 
        onClick={toggleCommentSection} 
        variant="tertiary" 
        size="small" 
        aria-label={isCommentSectionVisible ? "Hide comments" : "Show comments"}
      >
        <MessageSquare />
        <span>{post.comments.length}</span>
      </Button>
      <Button onClick={handleShare} variant="tertiary" size="small" aria-label="Share post">
        <Share2 />
        <span>{post.shares}</span>
      </Button>
      <Button 
        onClick={handleSave} 
        variant="tertiary" 
        size="small" 
        aria-label={isSaved ? 'Unsave post' : 'Save post'}
      >
        <Bookmark 
          fill={isSaved ? 'var(--color-primary)' : 'none'}
          stroke={isSaved ? 'var(--color-primary)' : 'currentColor'}
        />
      </Button>
      {isCurrentUser && (
        <>
          <Button onClick={() => setEditing(true)} variant="tertiary" size="small" aria-label="Edit post">
            <Edit2 />
          </Button>
          <Button onClick={handleDeleteClick} variant="tertiary" size="small" aria-label="Delete post">
            <Trash2 />
          </Button>
        </>
      )}
    </div>
  );

  const memoizedComments = useMemo(() => {
    return (
      <div className={styles.postComments}>
        <h4>Comments ({post.comments.length})</h4>
        {post.comments.map((comment, index) => (
          <div key={index} className={styles.comment}>
            <strong>{commenters[comment.userId] || 'Anonymous'}: </strong>
            <span>{comment.content}</span>
          </div>
        ))}
        <form onSubmit={handleComment} className={styles.commentForm}>
          <input 
            ref={commentInputRef}
            value={comment} 
            onChange={(e) => setComment(e.target.value)} 
            placeholder="Add a comment" 
            aria-label="Add a comment"
          />
          <Button type="submit" variant="primary" size="small">Comment</Button>
        </form>
      </div>
    );
  }, [post.comments, commenters, comment, handleComment]);

  return (
    <>
      <div className={`${styles.postItem} ${isPostExpanded || isDetailView ? styles.expanded : ''}`}>
        {editing && isCurrentUser ? (
          renderEditForm()
        ) : (
          <>
            <div
              className={styles.postHeader}
              onClick={() => !isDetailView && togglePostExpansion()}
              role={isDetailView ? undefined : "button"}
              tabIndex={isDetailView ? undefined : 0}
              aria-expanded={isPostExpanded}
              aria-label={`${post.name}. ${isPostExpanded ? 'Click to collapse' : 'Click to expand'}`}
            >
              {renderPostContent()}
            </div>
            {canInteract && (
              <>
                {renderPostActions()}
                {shareMessage && <div className={styles.shareMessage}>{shareMessage}</div>}
                {isPostExpanded && (
                  <div className={`${styles.postComments} ${isCommentSectionVisible ? '' : styles.hidden}`}>
                    {memoizedComments}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        message="Are you sure you want to delete this post? This action cannot be undone."
      />
    </>
  );
};

export default PostItem;