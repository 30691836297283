import React from 'react';
import PostItem from './PostItem';
import { useHits, useSearchBox } from 'react-instantsearch';
import { usePost } from '../hooks/usePost';

const PostList = ({ defaultPosts, isCurrentUser, activeTab }) => {
  const { hits } = useHits();
  const { query } = useSearchBox();
  const { posts } = usePost();

  const displayPosts = query 
    ? hits 
    : (activeTab === 'posts' ? (posts.length > 0 ? posts : defaultPosts) : defaultPosts);

  return (
    <div>
      <h3>Posts</h3>
      {displayPosts.length === 0 ? (
        <p>No posts to display.</p>
      ) : (
        displayPosts.map(post => (
          <PostItem
            key={post.objectID || post.id}
            post={post}
            isCurrentUser={isCurrentUser}
          />
        ))
      )}
    </div>
  );
};

export default PostList;