import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Button from './Button';
import './PostPreview.css';

const PostPreview = ({ post, onPostCreated, onClose }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedPost, setEditedPost] = useState(post);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPost(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await onPostCreated(editedPost);
      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (editMode) {
    return (
      <div className="post-preview edit-mode">
        <input
          name="name"
          value={editedPost.name}
          onChange={handleChange}
          placeholder="Post Title"
        />
        <textarea
          name="description"
          value={editedPost.description}
          onChange={handleChange}
          placeholder="Post Description"
        />
        <input
          name="link"
          value={editedPost.link}
          onChange={handleChange}
          placeholder="Link"
        />
        <select
          name="privacy"
          value={editedPost.privacy}
          onChange={handleChange}
        >
          <option value="public">Public</option>
          <option value="friends">Friends</option>
          <option value="private">Private</option>
        </select>
        <div className="search-metadata">
          {editedPost.searchMetadata.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
            </span>
          ))}
        </div>
        <div className="post-preview-actions">
          <Button onClick={() => setEditMode(false)} variant="secondary" size="small">
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={isLoading} variant="primary" size="small">
            {isLoading ? 'Saving...' : 'Save Post'}
          </Button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  }

  return (
    <div className="post-preview">
      <h3>{post.name}</h3>
      <div className="post-description">
        <ReactMarkdown>{post.description}</ReactMarkdown>
      </div>
      {post.link && (
        <a href={post.link} target="_blank" rel="noopener noreferrer">
          {post.link}
        </a>
      )}
      <p>Privacy: {post.privacy}</p>
      <div className="search-metadata">
        {post.searchMetadata.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
          </span>
        ))}
      </div>
      <div className="post-preview-actions">
        <Button onClick={handleEdit} variant="secondary" size="small">
          Edit
        </Button>
        <Button onClick={handleSave} disabled={isLoading} variant="primary" size="small">
          {isLoading ? 'Saving...' : 'Create Post'}
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default PostPreview;