// src/components/AddFriendByUsername.js

import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, setDoc, doc, arrayUnion } from 'firebase/firestore';
import Button from './Button';

const AddFriendByUsername = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddFriend = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    if (!username.trim()) {
      setMessage('Please enter a valid username');
      setIsLoading(false);
      return;
    }

    const currentUser = auth.currentUser;

    try {
      const q = query(collection(db, "users"), where("username", "==", username.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage('No user found with this username');
        setIsLoading(false);
        return;
      }

      const friendDoc = querySnapshot.docs[0];
      const friendId = friendDoc.id;

      if (friendId === currentUser.uid) {
        setMessage('You cannot add yourself as a friend');
        setIsLoading(false);
        return;
      }

      // Send friend request
      await setDoc(doc(db, 'users', friendId), {
        friendRequests: arrayUnion(currentUser.uid)
      }, { merge: true });

      setMessage('Friend request sent successfully');
      setUsername('');
    } catch (error) {
      console.error("Error sending friend request: ", error);
      if (error.code === 'permission-denied') {
        setMessage('Permission denied. Please try again later.');
      } else {
        setMessage('An error occurred while sending friend request');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleAddFriend} className="add-friend-form">
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Enter friend's username"
        className="add-friend-input"
        disabled={isLoading}
      />
      <Button 
        type="submit" 
        variant="primary" 
        size="medium"
        disabled={isLoading}
        className="add-friend-button"
      >
        {isLoading ? 'Sending...' : 'Send Friend Request'}
      </Button>
      {message && <p className="add-friend-message">{message}</p>}
    </form>
  );
};

export default AddFriendByUsername;