import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, Share2, MessageCircle, UserPlus } from 'react-feather';
import { formatDistanceToNow } from 'date-fns';
import './NotificationItem.css';

const getIcon = (type) => {
  switch (type) {
    case 'like':
      return <Heart size={20} className="notification-icon like" />;
    case 'share':
      return <Share2 size={20} className="notification-icon share" />;
    case 'comment':
      return <MessageCircle size={20} className="notification-icon comment" />;
    case 'follow':
      return <UserPlus size={20} className="notification-icon follow" />;
    default:
      return null;
  }
};

const NotificationItem = ({ notification, onClose }) => {
  const { type, content, createdAt, relatedId } = notification;
  const navigate = useNavigate();
  
  const formattedDate = createdAt && createdAt.toDate ? 
    formatDistanceToNow(createdAt.toDate(), { addSuffix: true }) : 
    'some time ago';

  const handleClick = () => {
    if (relatedId) {
      onClose(); // Close the notifications dropdown
      navigate(`/post/${relatedId}`);
    }
  };

  return (
    <div className="notification-item" onClick={handleClick}>
      {getIcon(type)}
      <div className="notification-content">
        <p className="notification-text">{content}</p>
        <span className="notification-time">{formattedDate}</span>
      </div>
    </div>
  );
};

export default NotificationItem;